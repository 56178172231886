import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';
import { LoginModel } from '../../models/login.model';
import { RegistroModel } from '../../models/registro.model';
import { SessionModel } from '../../models/sesion.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string = null;
  authEmail = '';
  
  authUrl = {
    current: '',
    next: ''
  };
  private dataPoliza = new BehaviorSubject({estado: 0});
  public dataPoliza$ = this.dataPoliza.asObservable();

  private estadoSession = new Subject<boolean>();
  public estadoSession$ = this.estadoSession.asObservable();

  constructor(private http: GeneralHttpService){}

  registro = (data: RegistroModel) => this.http.post(`api/v1/account/registro`, data);

  login = (data: LoginModel) => this.http.post(`api/v1/account/login`, data).pipe(map( (m: any) => {
    m.data.date = Date.now();
    this.setSession(m.data);
    this.estadoSession.next(true);
    return m;
  }))

  setSession(data: any): void {
    this.token = data?.token;
    localStorage.setItem('session', JSON.stringify(data));
  }

  getSession(): SessionModel {
    return JSON.parse(localStorage.getItem('session'));
  }

  getToken(): string {
    if (this.token === null){
      const dataSession = JSON.parse(localStorage.getItem('session'));
      this.token =  dataSession?.token;
    }
    return this.token;
  }

  getTokenCliente(data: any): Observable<any>  {
    return this.http.post(`api/v1/Account/Registro/OnbordingToCustomer`, data);
  }

  esUsuarioOnboarding(){
    const dataSession = this.getSession();
    return dataSession?.roles[0] === 'Onbording' ? true : false;
  }

  currentUrlIsPlanes = () => this.authUrl.current === '/onboarding/autos?paso=datosPlanes'  ? true : false;

  closeSession(): void{
    this.setSession(null);
    this.estadoSession.next(false);
  }

  verificarCuenta(email: string, token: string): Observable<any> {
    return this.http.get(`api/v1/Account/validacionemail?email=${email}&token=${token}`);
  }

  solicitarNuevaContrasena(email: string): Observable<any> {
    return this.http.post(`api/v1/Account/recuperar-contrasena`, email);
  }

  restaurarContrasena(data: any ): Observable<any>{
    return this.http.post(`api/v1/Account/restaurar-contrasena`, data);
  }

  cambiarContrasena(data: any ): Observable<any>{
    return this.http.post(`api/v1/Account/cambiar-contrasena`, data);
  }

  onboardingUser(): Observable<any> {
    return this.http.get(`api/v1/Account/Registro/Onbording`);
  }

  verificarCodigo(data: any): Observable<any> {
    return this.http.post(`api/v1/Account/validacionemail`, data);
  }

  enviarCodigoAlCorreo(email: string): Observable<any> {
    return this.http.get(`api/v1/Account/enviaremailconfirmacion?email=${email}`)
  }

}
